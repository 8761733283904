import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@molecules';
import { Content, SimpleHero } from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';

const ContentDetailTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { siteTitle, sharedLabels } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent,
    disableAlertBar, enLanguagePageLinks, pageInOtherLanguages,
  } = page || {};
  const { title, heroImages, intro, content, heroColor } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription && metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme="transparent"
      settings={settings}
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
      footerBackground="white"
      heroLogo={heroColor}
    >
      <SimpleHero
       title={title}
       images={heroImages}
       heroColor={heroColor}
       intro={(heroImages && heroImages.length > 0) ? "" : intro}
      />
      <Content
        title={(heroImages && heroImages.length > 0) ? title : ""}
        intro={(heroImages && heroImages.length > 0) ? intro : ""}
        body={content}
        sharedLabels={sharedLabels}
        pageType="contentDetail"
      />
    </Layout>
  );
};

export default ContentDetailTemplate;

export const contentDetailPageQuery = graphql`
  query ContentContentDetailBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentDetail {
          title
          heroColor
          heroImages {
            gatsbyImageData(layout: FULL_WIDTH, backgroundColor: "#091f2f", quality: 100)
            description
            contentful_id
          }
          intro {
            raw
            references {
              ... on ContentfulPage {
                path
                contentful_id
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
            }
          }
          content {
            raw
            references {
              ... on ContentfulPage {
                path
                contentful_id
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulComponentMediaEmbed {
                contentful_id
                embedType
                caption {
                  raw
                }
                internal {
                  type
                }
                image {
                  gatsbyImageData
                  description
                }
              }
              ... on ContentfulComponentPullQuote {
                contentful_id
                citationText
                quote {
                  raw
                }
                internal {
                  type
                }
              }
              ... on ContentfulComponentForm {
                contentful_id
                submitButtonLabel
                submitButtonDesktopWidth
                submitButtonMobileWidth
                postUrl
                contentfulfields {
                  id
                  name
                  label
                  helpText
                  required
                  type
                  helpText
                  desktopWidth
                  mobileWidth
                  characterLimit
                  errorMessage
                  options {
                    key
                    value
                  }
                }
                internal {
                  type
                }
                legalDisclaimer {
                  raw
                }
                postSubmitMessage {
                  raw
                }
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
